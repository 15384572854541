import React from "react";
// Styles
import "./ReservarSection.css";
// Material UI
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Typography } from "@material-ui/core";
import Divider from "@mui/material/Divider";
import { Button } from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
// Modules
import { Fragment } from "react";
// Components
import ProductsSelected from "./ProductsSelected";
import Total from "./ProductsSelected/components/Total";
// Hooks
import { useStateValue } from "../../../../../hooks/StateProvider";
import { useHistoryEffects } from "../../../../../hooks/useHistoryEffects.js";
// Context
import { useContext, useState, useEffect } from "react";
import {
  Context,
  Context2,
  Context3,
  Context5,
  Context7,
} from "../../../../../context/Context";
// Firestore
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../../../../config";

const ReservarSection = (props) => {
  // Se recorre los productos seleccionados
  const [{ basket }, dispatch] = useStateValue();
  function FormRow() {
    return (
      <Fragment>
        {basket.map((item) => (
          <Grid key={item.id} item lg={12}>
            <ProductsSelected product={item} />
          </Grid>
        ))}
      </Fragment>
    );
  }

  // Obtener datos del context
  const { reservarSectionDisplay } = useContext(Context2);
  const [showReservarSection, setShoweReservarSection] = reservarSectionDisplay;

  // Funcion para cerrar ReservarSection (carrito de compras) con el boton x
  const cerrarReservarSection = () => {
    setShoweReservarSection(!showReservarSection);
    document.body.style.overflow = "auto";
  };

  // Obtener valor del total a pagar y precio minimo para contratacion correspondiente al valor del inflable mas barato: casita pelotero
  const [total, setTotal] = useState();
  const [precioMínimo, setPrecioMínimo] = useState();

  // Declaro variables del context
  const { date } = useContext(Context);
  const [stateDate, setStateDate] = date;
  const { cityZona } = useContext(Context3);
  const [stateCityZona, setStateCityZona] = cityZona;
  const { dateMonth } = useContext(Context5);
  const [stateDateMonth, setStateDateMonth] = dateMonth;
  const { precioMinimo } = useContext(Context7);
  const [statePrecioMinimo, setStatePrecioMinimo] = precioMinimo;

  const mesZona = stateDateMonth + "-" + stateCityZona;

  useEffect(() => {
    // Actualizar el valor total
    const newTotal = basket.reduce((amount, item) => amount + item.price, 0);
    setTotal(newTotal);

    // Obtener el precio mínimo mediante una llamada a la base de datos
    if (stateDate !== null && stateDate !== undefined && stateCityZona !== 0) {
      getDoc(doc(db, "PRECIO", mesZona)).then((docSnap) => {
        setPrecioMínimo(docSnap.data()[0]);
      });
    }
  }, [basket, stateDate, stateCityZona, mesZona]);

  useEffect(() => {
    setStatePrecioMinimo(precioMínimo);
  }, [precioMínimo]);

  // Cerrar reservarSection con flecha atras
  useHistoryEffects(showReservarSection, setShoweReservarSection);

  return (
    <div>
      <Box
        zIndex="5000"
        className={`contenedorReservarSection  ${
          showReservarSection && "active"
        }`}
        sx={{ flexGrow: 1 }}
      >
        <Grid container>
          {/* "Carrito" y boton cerrar x */}
          <Grid item xs={12} sm={12} md={12}>
            <div style={{ display: "flex" }}>
              <Typography style={{ alignSelf: "center" }} variant="h6">
                Carrito
              </Typography>
              <Box flexGrow="1"></Box>
              <Button
                className="cerrar-button"
                onClick={cerrarReservarSection}
                size="small"
                variant="contained"
                disableElevation
                startIcon={
                  <Box
                    component="span"
                    sx={{
                      fontSize: "18px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ClearIcon />
                  </Box>
                }
              >
                <Typography variant="inherit">Cerrar</Typography>
              </Button>
            </div>
            <Divider style={{ marginTop: "5px" }} />
          </Grid>
          {/* Productos seleccionados */}
          <Grid item xs={12} sm={12} md={12}>
            {/* Se muestran los productos seleccionados */}
            <FormRow />
          </Grid>
          {/* Total */}
          {basket.length > 0 && total >= precioMínimo ? (
            <Grid item xs={12} sm={12} md={12}>
              <Typography align="center" gutterBottom variant="h4">
                <Total
                  setReservarSectionDisplay={props.setReservarSectionDisplay}
                />
              </Typography>
            </Grid>
          ) : total !== 0 && total < precioMínimo ? (
            <Grid item xs={12} sm={12} md={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ marginTop: "2rem !important" }}
              >
                <Typography variant="inherit" className="sin-productos-txt">
                  No se alcanza el mínimo de contratación de ${" "}
                  {precioMínimo.toLocaleString().replace(",", ".")}
                </Typography>
                <Button
                  className="finalizar-reserva-button-disabled"
                  variant="contained"
                  disabled
                >
                  Finalizar Reserva
                </Button>
                <br />
                <Button
                  className="seguir-reservando-button"
                  color="secondary"
                  onClick={cerrarReservarSection}
                >
                  Seguir Reservando
                </Button>
              </Box>
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={12}>
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                style={{ marginTop: "10px" }}
              >
                <Typography variant="inherit" className="sin-productos-txt">
                  No hay productos en el Carrito
                </Typography>
                <Button
                  className="finalizar-reserva-button-disabled"
                  variant="contained"
                  disabled
                >
                  Finalizar Reserva
                </Button>
                <br />
                <Button
                  className="seguir-reservando-button"
                  color="secondary"
                  style={{ marginTop: "2rem" }}
                  onClick={cerrarReservarSection}
                >
                  Seguir Reservando
                </Button>
              </Box>
            </Grid>
          )}
        </Grid>
        <br />
        <br />
      </Box>
      {/* Div que oscurece la pantalla cuando se abre ReservarSection */}
      <div
        onClick={cerrarReservarSection}
        className={`fondoOscuroTransparente  ${
          showReservarSection && "active"
        }`}
      ></div>
    </div>
  );
};

export default ReservarSection;
