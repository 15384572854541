import React from "react";
// Styles
import "./ProductsView.css";
// Material UI
import Grid from "@mui/material/Grid";
import { Button } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import { Typography } from "@material-ui/core";
import DialogTitle from "@mui/material/DialogTitle";
// Modules
import { Link } from "react-scroll";
// Data
import products from "../../../../../product-data";
// Components
import CarouselImages from "./components/CarouselImages";
import { useEffect, useState } from "react";
// Hooks
import { actionTypes } from "../../../../../reducer";
import { useStateValue } from "../../../../../hooks/StateProvider";
import { IsDateWithinRange } from "../../../../../hooks/dateUtils";
// Context
import { useContext } from "react";
import { Context2 } from "../../../../../context/Context";

function ProductsView(props) {
  // Valor booleano si es temporada de acuaticos
  const isWithinRange = IsDateWithinRange();

  const price = Number(props.price);
  const formattedPrice = isNaN(price)
    ? "-"
    : price.toLocaleString().replace(",", ".");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [{ basket }, dispatch] = useStateValue();
  // Obtener datos del context
  const { reservarSectionDisplay } = useContext(Context2);
  const [stateReservarSectionDisplay, setStateReservarSectionDisplay] =
    reservarSectionDisplay;
  // Dialog
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
    document.body.style.overflow = "auto";
  };
  const [activeSection, setActiveSection] = useState("");

  const handleSetActiveSection = (sectionId) => {
    setActiveSection(sectionId);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const addToBasket = () => {
    const id = props.id;
    const item = props.item;
    const name = props.name;
    const price = props.price;
    const image = props.image;
    const size = props.size;
    const age = props.age;
    const city = props.city;
    const date = props.date;

    if (city !== 0 && date !== undefined) {
      props.handleDialogClose();
      let productExist = false;
      if (basket.length > 0) {
        for (let i = 0; i < basket.length; i++) {
          if (basket[i].id === props.id) {
            productExist = true;
            break;
          }
        }
        if (productExist === false) {
          dispatch({
            type: actionTypes.ADD_TO_BASKET,
            item: {
              id,
              item,
              name,
              price,
              image,
              size,
              age,
            },
          });
        }
      } else {
        dispatch({
          type: actionTypes.ADD_TO_BASKET,
          item: {
            id,
            item,
            name,
            price,
            image,
            size,
            age,
          },
        });
      }

      // Abrir ReservarSection
      setStateReservarSectionDisplay("block");
      document.body.style.overflow = "hidden";
    } else {
      setOpen(true);
      document.body.style.overflow = "hidden";
    }
  };

  const product = products.find((product) => product.id === props.id);
  const productName = product ? product.name : "";
  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          sx={{ marginLeft: { md: 6, lg: 6 } }}
          order={{ xs: 2, sm: 2, md: 1, lg: 1 }}
        >
          <CarouselImages id={props.id} />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={5}
          lg={5}
          order={{ xs: 1, sm: 1, md: 2, lg: 2 }}
        >
          <Grid
            container
            className="contenedorNamePriceButton"
            spacing={3}
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                item
                justifyContent={windowWidth <= 900 ? "center" : "flex-start"}
                sx={{
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "left",
                    lg: "left",
                  },
                }}
                alignItems="center"
                className="name"
              >
                {productName}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                justifyContent={windowWidth <= 900 ? "center" : "flex-start"}
                alignItems="center"
                className="price"
              >
                $ {formattedPrice}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <Grid
                container
                justifyContent={windowWidth <= 900 ? "center" : "flex-start"}
                alignItems="center"
              >
                {props.consultar === undefined ? (
                  props.productDisponibilidad === "habilitado" ||
                  props.productDisponibilidad === undefined ? (
                    props.filtro.includes("acuaticos") && !isWithinRange ? (
                      <>
                        <Typography
                          variant="inherit"
                          align="center"
                          style={{ color: "#D9AE48" }}
                          className="sin-productos-txt"
                        >
                          Temporada a partir del 1 de octubre al 31 de marzo
                        </Typography>
                        <Button
                          className="finalizar-reserva-button-disabled"
                          variant="contained"
                          onClick={addToBasket}
                          disabled
                        >
                          Agregar al Carrito
                        </Button>
                      </>
                    ) : (
                      <Button
                        className="finalizar-reserva-button"
                        variant="contained"
                        onClick={addToBasket}
                      >
                        Agregar al Carrito
                      </Button>
                    )
                  ) : (
                    <Button
                      className="finalizar-reserva-button-disabled"
                      variant="contained"
                      onClick={addToBasket}
                      disabled
                    >
                      Agregar al Carrito
                    </Button>
                  )
                ) : (
                  <Button
                    className="finalizar-reserva-button"
                    variant="contained"
                    href="https://wa.me/5491163697737"
                    target="_blank"
                  >
                    Consultar por WhatsApp
                  </Button>
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} marginTop={1}>
              <Grid
                container
                justifyContent={{
                  xs: "center",
                  sm: "center",
                  md: "space-between",
                  lg: "space-between",
                }}
                spacing={1}
              >
                <Grid item xs={6} sm={4} md={6} lg={6} marginBottom={5}>
                  <p>
                    <span className="texto-resaltado-dialog">Descripción:</span>{" "}
                    {props.descripcion.split("+").map((linea, index) => (
                      <span key={index}>
                        {index > 0 && "+"}{" "}
                        {/* Agrega + solo si no es la primera línea */}
                        {linea.trim()}
                        <br />
                      </span>
                    ))}
                  </p>

                  {props.plotter === true ? (
                    <div className="plotter-txt-container">
                      <span>Incluye Plotter </span>
                      <span
                        className="plotter_txt"
                        onClick={() => setOpen2(true)}
                      >
                        ver lista
                      </span>
                    </div>
                  ) : null}
                </Grid>
                <Grid item xs={5} sm={3} md={5.5} lg={5.5}>
                  <p>
                    <span className="texto-resaltado-dialog">Tamaño:</span>{" "}
                    {props.size}
                  </p>
                  <br />
                  <p>
                    <span className="texto-resaltado-dialog">Edad:</span>{" "}
                    {props.age}
                  </p>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* Dialog alert */}
      <Dialog
        disableScrollLock={true}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="dialog-container">
          <DialogTitle id="alert-dialog-title">
            {"Campos incompletos"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              Por favor, selecciona una{" "}
              <span className="texto-resaltado-dialog">FECHA</span> y una{" "}
              <span className="texto-resaltado-dialog">CIUDAD</span> para poder
              reservar, ver disponibilidad y precios.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Link
              style={{ textDecoration: "none" }}
              to="tienda"
              smooth={true}
              offset={-80}
              activeClass="active-scroll-spy"
              onSetActive={handleSetActiveSection}
            >
              <Button
                onClick={() => {
                  handleClose();
                  props.handleDialogClose();
                }}
                className="button-dialog"
                color="success"
              >
                Aceptar
              </Button>
            </Link>
          </DialogActions>
        </DialogContent>
      </Dialog>
      {/* Dialog alert */}
      <Dialog
        disableScrollLock={true}
        open={open2}
        onClose={() => setOpen2(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
      >
        <DialogContent className="dialog-container">
          <DialogTitle id="alert-dialog-title">
            {"Lista de Plotter"}
          </DialogTitle>
          <DialogContent>
            <p>
              Sujeto a disponibilidad, consultar por{" "}
              <a
                href="https://api.whatsapp.com/send/?phone=5491163697737&text&type=phone_number&app_absent=0"
                rel="noreferrer"
                target="_blank"
              >
                whatsapp
              </a>
              .
            </p>
            <Grid
              container
              marginTop={2}
              sx={{
                justifyContent: {
                  xs: "flex-end",
                  sm: "flex-end",
                  md: "space-between",
                  lg: "space-between",
                },
              }}
            >
              <Grid item xs={10} sm={12} md={3} lg={3}>
                <ul>
                  <li>ANIMALITOS</li>
                  <li>BACKYARDIGANS</li>
                  <li>BARBIE</li>
                  <li>CAMPANITA</li>
                  <li>CARS</li>
                  <li>DOCTORA JUGUETES</li>
                  <li>ENCANTO</li>
                  <li>FUTBOL BARCELONA</li>
                  <li>FUTBOL BOCA</li>
                  <li>FUTBOL GENERICO</li>
                  <li>FUTBOL INDEPENDIENTE</li>
                  <li>FUTBOL MESSI/ARGENTINA</li>
                </ul>
              </Grid>
              <Grid item xs={10} sm={12} md={3} lg={3}>
                <ul>
                  <li>FUTBOL QUILMES</li>
                  <li>FUTBOL RACING</li>
                  <li>FUTBOL RIVER</li>
                  <li>FUTBOL SAN LORENZO</li>
                  <li>FROZEN</li>
                  <li>FRUTILLITAS</li>
                  <li>HENRY MONSTRUITO</li>
                  <li>HEROES EN PIJAMA</li>
                  <li>HOMBRE ARAÑA</li>
                  <li>JAKE Y LOS PIRATAS</li>
                  <li>KITTY</li>
                  <li>MADAGASCAR</li>
                </ul>
              </Grid>
              <Grid item xs={10} sm={12} md={3} lg={3}>
                <ul>
                  <li>MICKEY</li>
                  <li>MINNIE</li>
                  <li>MINIONS</li>
                  <li>PATRULLA CANINA</li>
                  <li>PEPPA PIG</li>
                  <li>POWER RANGERS</li>
                  <li>PRINCESAS</li>
                  <li>RAPUNZEL</li>
                  <li>SAPO PEPE</li>
                  <li>SHERIFF CALLIE</li>
                  <li>SOFIA</li>
                  <li>SUPERHEROES</li>
                  <li>TOY STORY</li>
                  <li>WINNI POOH</li>
                </ul>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setOpen2(false)} className="button-dialog">
              Cerrar
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
      <br />
      <br />
    </>
  );
}

export default ProductsView;
